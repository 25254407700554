import type { FormInput } from '@/utils/client/forms/intervention/edit'
import type { PartialDeep } from 'type-fest'
import type { FormContext } from 'vee-validate'

import type { FetchCustomerDevicesByCustomer } from './data/panels/customer-devices'
import type { FetchIntervention } from './data/panels/interventions'
import type { FetchServicesWithPartsByModel } from './data/panels/services'
import { zodSchema } from '@/utils/client/forms/intervention/edit'
import { toTypedSchema } from '@vee-validate/zod'
import {
  addMinutes,
  roundToNearestMinutes,
} from 'date-fns'
import { useForm } from 'vee-validate'

type PlannedService = Required<NonNullable<FormInput['planned_services']>>[number]
type PlannedServiceService = Required<NonNullable<PlannedService['service']>>
type AvailableSparePart = Required<NonNullable<PlannedServiceService['require_spare_parts']>>[number]
type ReservedSparePart = Required<NonNullable<PlannedService['require_spare_part']>>[number]

interface InterventionFormContext {
  form: FormContext<PartialDeep<FormInput>>
  setDevice: (device: FormInput['device_model']) => void
  setCustomerDevice: (customerDevice: FormInput['customer_device']) => void
  includeSparePart: (serviceIndex: number, item: AvailableSparePart) => void
  removeSparePart: (serviceIndex: number, item: ReservedSparePart) => void
  receiveStock: (serviceIndex: number, item: ReservedSparePart) => void
  setService: (serviceIndex: number, service: PlannedServiceService) => void
  computeEstimatedDate: (
    date: FormInput['date_scheduled'],
    time: FormInput['estimated_time'],
  ) => void
  computeEstimatedTime: (
    plannedServices: FormInput['planned_services'],
  ) => void
  onSubmit: () => void
  submitAndSchedule: () => void

  intervention: Ref<FetchIntervention | null>
  interventionPending: Ref<boolean>
  interventionRefresh: () => Promise<void>

  customerDevices: Ref<FetchCustomerDevicesByCustomer | null>
  customerDevicesPending: Ref<boolean>
  customerDevicesRefresh: () => Promise<void>

  modelServices: Ref<FetchServicesWithPartsByModel | null>
  modelServicesPending: Ref<boolean>
  modelServicesRefresh: () => Promise<void>

  showEditCustomer: Ref<boolean>
  showEditDevice: Ref<boolean>
  showPriceDetails: Ref<boolean>
  isPayed: Ref<boolean>
  /**
   * @deprecated
   */
  isLocked: Ref<boolean>
}

const interventionFormContext = Symbol(
  'intervention-form-context',
) as InjectionKey<InterventionFormContext>

const validationSchema = toTypedSchema(zodSchema)
const initialValues: FormInput = {
  id: '',
  status: 'new',
  customer: { id: '' },
  customer_device: { id: '' },
  device_model: { id: '' },
  planned_services: [{ service: { id: '' } }],
  items: [],
  invoices: [],
  estimated_time: null,
  date_status_updated: null,
  date_scheduled: null,
  date_estimated: null,
  on_hold: false,
  comment: '',
  comment_invoice: '',
  comment_hold: '',
} as const

export function createInterventionFormContext() {
  const form = useForm({
    validationSchema,
    keepValuesOnUnmount: true,
    initialValues,
  })

  const statuses = computed(
    () => form.values?.planned_services
      ?.filter(s => s?.service?.id)
      ?.map(s => (s?.custom_status || form.values.status) as string) ?? [],
  )

  // update intervention status
  watch(statuses, (statuses: string[]) => {
    if (!statuses || !statuses.length) {
      return
    }

    // check highest status priority
    if (statuses.includes('pending_restocking')) {
      form.setFieldValue('status', 'pending_restocking')
      return
    }

    if (statuses.includes('require_restocking')) {
      form.setFieldValue('status', 'require_restocking')
      return
    }

    if (statuses.includes('started')) {
      form.setFieldValue('status', 'started')
      return
    }

    if (statuses.includes('new')) {
      form.setFieldValue('status', 'new')
      return
    }

    if (statuses.every(s => s === 'complete')) {
      form.setFieldValue('status', 'complete')
    }
  })

  // on device model change, reset customer device and planned services
  function setDevice(device: FormInput['device_model']) {
    form.setFieldValue('device_model', device)
    form.setFieldValue('planned_services', [{ service: { id: '' } }])

    if (form.values?.customer_device?.device_model?.id === device?.id) {
      return
    }
    form.setFieldValue('customer_device', { id: '' })
  }

  // on customer device change, reset device model
  function setCustomerDevice(customerDevice: FormInput['customer_device']) {
    form.setFieldValue('customer_device', customerDevice)

    if (!customerDevice?.id) {
      return
    }
    if (form.values?.device_model?.id === customerDevice?.device_model?.id) {
      return
    }
    setDevice(
      customerDevice?.device_model,
    )
  }

  // compute estimated end date from scheduled one
  function computeEstimatedDate(
    date: FormInput['date_scheduled'],
    time: FormInput['estimated_time'],
  ) {
    if (!date || !time) {
      form.setFieldValue('date_estimated', null)
      return
    }
    form.setFieldValue(
      'date_estimated',
      roundToNearestMinutes(addMinutes(date, time), {
        nearestTo: 15,
      }),
    )
  }

  // update estimated time from planned services
  function computeEstimatedTime(
    plannedServices: FormInput['planned_services'],
  ) {
    if (!plannedServices?.length) {
      form.setFieldValue('estimated_time', null)
      return
    }
    const time = plannedServices.reduce(
      (acc, s) => acc + (s?.service?.estimated_time ?? 0),
      0,
    )
    form.setFieldValue('estimated_time', time)

    if (form.values.date_scheduled) {
      computeEstimatedDate(form.values.date_scheduled, time)
    }
  }

  function includeSparePart(serviceIndex: number, item: AvailableSparePart) {
    const sparePartId = item?.spare_part?.id
    if (!sparePartId) {
      return
    }
    const value = form.values?.planned_services?.[serviceIndex]
    if (!value) {
      return
    }

    let requireSparePart = [...(value?.require_spare_part ?? [])]

    if (!item.is_optional) {
      const otherRequiredId = value.service?.require_spare_parts
        ?.filter(p => !p.is_optional)
        ?.map(p => p?.spare_part?.id) ?? []
      requireSparePart = requireSparePart?.filter(p => !otherRequiredId?.includes(p?.spare_part?.id)) ?? []
    }

    const currentReservedStockIdx = requireSparePart.findIndex(p => p?.spare_part?.id === sparePartId) ?? -1
    const currentReservedStock = currentReservedStockIdx >= 0 ? { ...requireSparePart[currentReservedStockIdx] } : undefined

    const newPart = {
      id: currentReservedStock?.id,
      quantity: item.quantity,
      spare_part: item.spare_part,
      stock_deducted: item.spare_part?.has_managed_stocks
        ? Math.min(item.spare_part?.in_stock ?? 0, item.quantity ?? 0)
        : 0,
      price_modifier: item.price_modifier,
    }

    if (currentReservedStockIdx >= 0) {
      requireSparePart.splice(currentReservedStockIdx, 1, newPart)
    }
    else {
      requireSparePart.push(newPart)
    }

    let newStatus = value.custom_status
    if (requireSparePart.some(p => p.quantity !== p.stock_deducted)) {
      newStatus = 'require_restocking'
    }
    else {
      newStatus = value.custom_status === 'require_restocking' ? 'new' : value.custom_status
    }

    form.setFieldValue(`planned_services.${serviceIndex}`, {
      ...value,
      require_spare_part: requireSparePart,
      custom_status: newStatus,
    })
  }

  function removeSparePart(serviceIndex: number, item: ReservedSparePart) {
    const sparePartId = item?.spare_part?.id
    if (!sparePartId) {
      return
    }
    const value = form.values?.planned_services?.[serviceIndex]
    if (!value) {
      return
    }

    const requireSparePart = [...(value?.require_spare_part ?? [])]
    const currentReservedStockIdx = requireSparePart.findIndex(p => p?.spare_part?.id === sparePartId) ?? -1

    if (currentReservedStockIdx >= 0) {
      requireSparePart.splice(currentReservedStockIdx, 1)
    }

    let newStatus = value.custom_status
    if (requireSparePart.some(p => p.quantity !== p.stock_deducted)) {
      newStatus = 'require_restocking'
    }
    else {
      newStatus = value.custom_status === 'require_restocking' ? 'new' : value.custom_status
    }

    form.setFieldValue(`planned_services.${serviceIndex}`, {
      ...value,
      require_spare_part: requireSparePart,
      custom_status: newStatus,
    })
  }

  function receiveStock(serviceIndex: number, item: ReservedSparePart) {
    const sparePartId = item?.spare_part?.id
    if (!sparePartId) {
      return
    }
    const value = form.values?.planned_services?.[serviceIndex]
    if (!value) {
      return
    }

    const requireSparePart = [...(value?.require_spare_part ?? [])]
    const currentReservedStockIdx = requireSparePart.findIndex(p => p?.spare_part?.id === sparePartId) ?? -1
    const currentReservedStock = currentReservedStockIdx >= 0 ? requireSparePart[currentReservedStockIdx] : undefined

    if (currentReservedStockIdx >= 0) {
      requireSparePart.splice(currentReservedStockIdx, 1, {
        ...currentReservedStock,
        stock_deducted: currentReservedStock?.quantity,
      })
    }
    // let newStatus = value.custom_status
    // if (requireSparePart.some(p => p.quantity !== p.stock_deducted)) {
    //   newStatus = 'require_restocking'
    // }
    // else {
    //   newStatus = value.custom_status === 'require_restocking' || value.custom_status === 'pending_restocking' ? 'new' : value.custom_status
    // }

    form.setFieldValue(`planned_services.${serviceIndex}`, {
      ...value,
      require_spare_part: requireSparePart,
      // custom_status: newStatus,
    })
  }

  function setService(serviceIndex: number, service: PlannedServiceService) {
    if (!form.values?.planned_services) {
      return
    }
    const planned = [...form.values!.planned_services!]

    const value = planned[serviceIndex] ?? {}
    const emptyServiceIndex = planned.findIndex(s => !s?.service?.id)

    planned[serviceIndex] = {
      ...value,
      custom_status: 'new',
      service,
      require_spare_part: [],
    }

    if (serviceIndex === emptyServiceIndex) {
      planned.push({
        custom_status: 'new',
        require_spare_part: [],
        discount: 0,
        discount_id: null,
        discount_label: '',
        discount_type: '',
        service: {
          id: '',
        },
      })
    }
    form.setFieldValue(`planned_services`, planned)

    nextTick(() => {
      const firstRequiredPart = service?.require_spare_parts?.find(p => !p.is_optional)
      if (firstRequiredPart) {
        includeSparePart(serviceIndex, firstRequiredPart)
      }

      computeEstimatedTime(form.values.planned_services)
    })
  }

  return {
    form,
    setDevice,
    setCustomerDevice,
    includeSparePart,
    removeSparePart,
    receiveStock,
    setService,
    computeEstimatedDate,
    computeEstimatedTime,
  }
}

export function provideInterventionFormContext(context: InterventionFormContext) {
  provide(interventionFormContext, context)
  return context
}

export function useInterventionFormContext() {
  const context = inject(interventionFormContext)
  if (!context)
    throw new Error('useInterventionFormContext: context not found')

  return context
}
