import type { DIRECTUS_MSG_OUTPUT } from '../../errors'
import { z } from 'zod'

export const MSG_OUTPUT = {
  fields: {
  },
} satisfies DIRECTUS_MSG_OUTPUT

export const zodSchema = z
  .object({
    id: z.string().nullable().optional(),
    status: z.string().nullable().optional(),
    customer: z
      .object({
        id: z.string(),
        first_name: z.string().nullable().optional(),
        last_name: z.string().nullable().optional(),
        company: z.string().nullable().optional(),
        email: z.string().nullable().optional(),
        messages_status: z.string().nullable().optional(),
        email_bouncing: z.boolean().nullable().optional(),
      })
      .optional()
      .nullable(),
    customer_device: z
      .object({
        id: z.string({
          invalid_type_error: 'Selectionner un appareil',
        }),
        serial_number: z.string().nullable().optional(),
        device_model: z.object({
          id: z.string(),
          name: z.string().nullable().optional(),
          device_category: z.string().nullable().optional(),
        }).nullable().optional(),
      })
      .optional()
      .nullable(),
    device_model: z.object({
      id: z.string(),
      name: z.string().nullable().optional(),
      device_category: z.string().nullable().optional(),
    }).nullable().optional(),
    planned_services: z.array(
      z.object({
        id: z.number().nullable().optional(),
        custom_status: z.string().nullable().optional(),
        discount: z.number().nullable().optional(),
        discount_type: z.string().nullable().optional(),
        discount_label: z.string().nullable().optional(),
        discount_id: z.string().nullable().optional(),
        order: z.number().nullable().optional(),
        require_spare_part: z
          .array(
            z.object({
              id: z.number().nullable().optional(),
              quantity: z.number().nullable().optional(),
              stock_deducted: z.number().nullable().optional(),
              price_modifier: z.number().nullable().optional(),
              spare_part: z
                .object({
                  id: z.string(),
                  name: z.string().nullable().optional(),
                  reference: z.string().nullable().optional(),
                  provider: z.string().nullable().optional(),
                  unitary_cost: z.number().nullable().optional(),
                  has_managed_stocks: z.boolean().nullable().optional(),
                  in_stock: z.number().nullable().optional(),
                })
                .nullable()
                .optional(),
            }),
          )
          .nullable()
          .optional(),
        service: z.object({
          id: z.string(),
          name: z.string().nullable().optional(),
          notes: z.string().nullable().optional(),
          estimated_time: z.number().nullable().optional(),
          tax_ratio: z.number().nullable().optional(),
          unitary_price: z.number().nullable().optional(),
          require_spare_parts: z
            .array(
              z.object({
                id: z.number().nullable().optional(),
                quantity: z.number().nullable().optional(),
                price_modifier: z.number().nullable().optional(),
                is_optional: z.boolean().nullable().optional(),
                spare_part: z
                  .object({
                    id: z.string(),
                    name: z.string().nullable().optional(),
                    reference: z.string().nullable().optional(),
                    provider: z.string().nullable().optional(),
                    unitary_cost: z.number().nullable().optional(),
                    has_managed_stocks: z.boolean().nullable().optional(),
                    in_stock: z.number().nullable().optional(),
                  })
                  .nullable()
                  .optional(),
              }),
            )
            .nullable()
            .optional(),
        }).nullable().optional(),
      }),
    ).nullable().optional(),
    items: z.array(
      z.object({
        id: z.number().nullable().optional(),
        quantity: z.number().nullable().optional(),
        discount: z.number().nullable().optional(),
        discount_type: z.string().nullable().optional(),
        discount_label: z.string().nullable().optional(),
        discount_id: z.string().nullable().optional(),
        item: z.object({
          id: z.string(),
          name: z.string().nullable().optional(),
          reference: z.string().nullable().optional(),
          provider: z.string().nullable().optional(),
          unitary_price: z.number().nullable().optional(),
          unitary_cost: z.number().nullable().optional(),
          tax_ratio: z.number().nullable().optional(),
          has_managed_stocks: z.boolean().nullable().optional(),
          is_second_hand: z.boolean().nullable().optional(),
          in_stock: z.number().nullable().optional(),
        }),
      }),
    ).nullable().optional(),
    invoices: z.array(
      z.object({
        id: z.number().nullable().optional(),
        type: z.string().nullable().optional(),
        payment_method: z.string().nullable().optional(),
        payment_method_id: z.string().nullable().optional(),
        total: z.number().nullable().optional(),
        discount_total: z.number().nullable().optional(),
        date_payed: z.date().or(z.string()).nullable().optional(),
      }),
    ).nullable().optional(),
    date_status_updated: z.date().nullable().optional(),
    date_scheduled: z.date().nullable().optional(),
    date_estimated: z.date().nullable().optional(),
    estimated_time: z.number().nullable().optional(),
    comment: z.string().nullable().optional().or(z.literal('')),
    comment_invoice: z.string().nullable().optional().or(z.literal('')),
    comment_hold: z.string().nullable().optional().or(z.literal('')),
    on_hold: z.boolean().nullable().optional(),
  })

export type FormInput = z.infer<typeof zodSchema>
